export const badgeVariantMap: any = {
  primary: "bg-primary text-primary-content border-primary",
  secondary: "bg-secondary text-secondary-content border-secondary",
  neutral: "bg-neutral text-neutral-content border-neutral",
  accent: "bg-accent text-accent-content border-accent",
  info: "bg-info text-info-content border-info",
  success: "bg-success text-success-content border-success",
  error: "bg-error text-error-content border-error",
  ghost: "bg-ghost text-ghost-content border-ghost",
  link: "bg-link text-link-content border-link",
};

// TODO add sizes
export const badgeSizeMap: any = {
  xs: "badge-xs",
  sm: "badge-sm",
  md: "badge-md",
  lg: "badge-lg",
};

export const DEFAULT_BADGE_VARIANT = "neutral";
export const DEFAULT_BADGE_SIZE = "md";

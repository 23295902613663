import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-lg text-sm font-medium transition-none disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-base-300 disabled:text-base-content disabled:border-base-300 disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        default:
          "border border-primary bg-primary text-primary-content hover:bg-primary/90",
        destructive:
          "border border-error bg-error text-error-content hover:bg-error/90",
        secondary:
          "border border-secondary bg-secondary text-secondary-content hover:bg-secondary/90",
        ghost:
          "border border-transparent bg-transparent text-base-content hover:bg-base-200 hover:border-base-200",
        link: "text-primary underline-offset-4 hover:underline",
        neutral:
          "border border-base-200 bg-neutral hover:bg-base-200 text-neutral-content",
        accent:
          "border border-accent bg-accent hover:bg-accent/90 text-accent-content",

        outline: "border border-base-200 bg-transparent hover:border-base-300",
        "outline-accent":
          "border border-accent bg-transparent hover:bg-accent hover:text-accent-content",

        glass:
          "bg-transparent duration-300 rounded-3xl backdrop-blur-md text-white drop-shadow-lg dark:bg-transparent dark:text-white border border-white/60 dark:border-white/60 shadow-lg hover:border-white/90 dark:hover:border-white/90 ",
        "glass-btn":
          "bg-white/20 rounded-full text-white dark:bg-white/20 dark:text-white backdrop-blur-md border border-white/10 dark:border-white/10  ",
        "glass-btn-transparent":
          "!bg-transparent rounded-full !text-white !dark:bg-transparent !dark:text-white !border-0",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 px-3",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

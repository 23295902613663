import { create } from "zustand";

export const LOGIN_MODAL = "login-modal";
export const CHAT_LOGIN_MODAL = "chat-login-modal";
export const CREDITS_MODAL = "credits-modal";
export const SUBSCRIBE_MODAL = "subscribe-modal";
export const AGE_CONFIRMATION = "age-confirmation";

export enum Modal {
  LOGIN_MODAL = "LOGIN-MODAL",
  CHAT_LOGIN_MODAL = "CHAT-LOGIN-MODAL",
  CREDITS_MODAL = "CREDITS-MODAL",
  SUBSCRIBE_MODAL = "SUBSCRIBE-MODAL",
  AGE_CONFIRMATION = "AGE-CONFIRMATION",
}

interface ModalState {
  activeModal?: Modal;
  modalProps: {};

  setActiveModal: (selectedModal?: Modal, props?: {}) => void;
  handleSubmit: (() => void) | null;
  setHandleSubmit: (handler: (() => void) | null) => void;
}

export const useModal = create<ModalState>()((set) => ({
  activeModal: undefined,
  modalProps: {},

  setActiveModal: (selectedModal, props = {}) =>
    set((state) => ({
      activeModal: selectedModal,
      modalProps: { ...state.modalProps, ...props },
    })),
  handleSubmit: null,
  setHandleSubmit: (handler: any) =>
    set((state) => ({ handleSubmit: handler })),
}));

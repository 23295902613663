import { cn } from "@/lib/utils/utils";
import { BadgeCheck } from "lucide-react";
import Badge from "../badge";
import { Button } from "../ui/button";

export default function ProfileCard({
  character,
  onClick = null,
  badge = null,
  badgeVariant = "neutral",
  withHoverEffect = false,
  withDescription = true,
  withVerifyBadge = false,
  withMatchBtn = false,
  noRadiusOnMobile = false,
  className = "",
  detailsClassName = "",
  badgeWrapperClassName = "",
  titleClassName = "",
  count = null,
}: any) {
  return (
    <div
      onClick={onClick}
      className={cn(
        "relative group/card aspect-[3/4] bg-base-200 ring-2 ring-transparent rounded-md hover:cursor-pointer",
        className,
        noRadiusOnMobile && "rounded-none sm:rounded-md",
      )} // TODO:       href={`/chat/${character.id}`}
    >
      <div
        className={cn(
          "h-full w-full rounded-md",
          noRadiusOnMobile && "!rounded-none sm:rounded-md",
        )}
      />
      <img
        src={character.avatar}
        className={cn(
          "absolute inset-0 h-full w-full object-cover rounded-md",
          noRadiusOnMobile && "rounded-none sm:rounded-md",
        )}
        alt=""
      />

      <div
        className={cn(
          "absolute inset-0 bg-gradient-to-t from-black/90 to-50% rounded-md",
          noRadiusOnMobile && "!rounded-none sm:rounded-md",
        )}
      />

      {count && (
        <div className="text-5xl md:text-7xl font-semibold absolute -top-1 -left-2 text-neutral text-stroke">
          {count}
        </div>
      )}

      {badge && (
        <div
          className={cn(
            "absolute top-0 left-0 px-3 pt-2",
            badgeWrapperClassName,
          )}
        >
          <Badge variant={badgeVariant}>{badge}</Badge>
        </div>
      )}
      <div
        className={cn(
          "absolute bottom-0 left-0 right-0 flex flex-col sm:flex-row justify-between sm:items-center gap-4 px-4 pb-4 text-secondary-content pt-[50%]",
          withHoverEffect && "lg:pb-2",
          detailsClassName,
        )}
      >
        <div className="">
          <div
            className={cn(
              "flex items-center gap-1 font-medium mb-1",
              titleClassName,
            )}
          >
            <span>{character.name}</span>

            {withVerifyBadge && (
              <span className="">
                <BadgeCheck
                  className="fill-blue-500"
                  size={18}
                  strokeWidth={1.62}
                />
              </span>
            )}
          </div>
          {withDescription && (
            <div
              className={cn(
                "text-sm line-clamp-2 text-secondary-content",
                withHoverEffect &&
                  "h-auto lg:h-0 overflow-hidden lg:group-hover/card:h-10 transition-all duration-300",
              )}
            >
              {character.description}
            </div>
          )}
        </div>

        {withMatchBtn && <Button className="shrink-0">Start Chatting</Button>}
      </div>
    </div>
  );
}

"use client";

import ProfileCard from "@/components/profile-card";
import Section from "@/components/section";
import { Modal, useModal } from "@/lib/slices/modal.slice";
import { User } from "@prisma/client";
import useEmblaCarousel from "embla-carousel-react";
import { useRouter } from "next/navigation";

export default function TrendingCarousel({
  trendingCharacters,
  user,
}: {
  trendingCharacters: any;
  user?: User | null;
}) {
  const [emblaRef] = useEmblaCarousel({
    dragFree: true,
    active: true,
    align: "start",
    slidesToScroll: 1,
  });
  const router = useRouter();
  const modals = useModal();

  return (
    <Section>
      <h2 className="text-lg font-semibold mb-3 lg:mb-6 mt-4">Trending 🔥</h2>

      <div className="embla" ref={emblaRef}>
        <div className="embla__container ms-2">
          {trendingCharacters?.map((character: any, index: number) => {
            return (
              <ProfileCard
                className="embla__slide max-w-[130px] md:max-w-[200px] w-full me-2 md:me-4"
                character={character}
                detailsClassName="px-2 pb-1"
                withVerifyBadge
                withDescription={false}
                onClick={async () => {
                  if (user?.id) {
                    router.push(`/chat/${character.id}`);
                  } else {
                    modals.setActiveModal(Modal.LOGIN_MODAL);
                  }
                }}
                key={index}
                count={index + 1}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
}

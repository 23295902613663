import { cn } from "@/lib/utils/utils";

export default function Section({
  id = "",
  className = "",
  children,
  ...props
}: any) {
  return (
    <section
      id={id}
      className={cn("px-3 lg:px-8 max-w-screen-xl mx-auto", className)}
      {...props}
    >
      {children}
    </section>
  );
}

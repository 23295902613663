"use client";

import ProfileCard from "@/components/profile-card";
import { Modal, useModal } from "@/lib/slices/modal.slice";
import { useRouter } from "next/navigation";

// Created a client component for grid as useRouter requires "use client" directive
export default function RecentGenerationsGrid({ characters, user }: any) {
  const router = useRouter();
  const modals = useModal();

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-3 lg:gap-4 w-full h-full">
      {characters?.map((character: any, index: number) => {
        return (
          <ProfileCard
            character={character}
            badge={character.label}
            key={index}
            detailsClassName="px-2"
            badgeWrapperClassName="px-2"
            onClick={async () => {
              if (user?.id) {
                router.push(`/chat/${character.id}`);
              } else {
                modals.setActiveModal(Modal.LOGIN_MODAL);
              }
            }}
          />
        );
      })}
    </div>
  );
}

import { cn, resolveMap } from "@/lib/utils/utils";
import {
  DEFAULT_BADGE_SIZE,
  DEFAULT_BADGE_VARIANT,
  badgeSizeMap,
  badgeVariantMap,
} from "./badge.data";

export default function Badge({
  children,
  variant = DEFAULT_BADGE_VARIANT,
  size = DEFAULT_BADGE_SIZE,
  outline = false,
  className = "",
}: any) {
  const resolvedBadgeVariant = resolveMap(
    badgeVariantMap,
    variant,
    DEFAULT_BADGE_VARIANT,
  );

  const resolvedSize = resolveMap(badgeSizeMap, size, DEFAULT_BADGE_SIZE);
  return (
    <div
      className={cn(
        "flex items-center gap-1 border-base-200 bg-base-100 text-base-content rounded-full border text-xs px-2 font-medium",
        resolvedBadgeVariant,
        resolvedSize,
        outline &&
          "border-current border-opacity-50 bg-transparent text-current",
        className,
      )}
    >
      {children}
    </div>
  );
}

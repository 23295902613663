"use client";

import Section from "@/components/section";
import { cn } from "@/lib/utils/utils";
import { User } from "@prisma/client";
import gtm from "react-gtm-module";

export function track(action: string, options: any = {}) {
  gtm.dataLayer({ dataLayer: { event: action, ...options } });
}

export default function LSCheckout({
  user,
  order,
}: {
  readonly user: User | null;
  order: any;
}) {
  if (!user) {
    return <div></div>;
  }
  track("purchase", {
    item_id: "prod_001",
    item_name: "EarlyBird",
    currency: "USD",
    price: `${order.price / 100}`,
    product_metadata: {
      unlimited: "True",
    },
    price_interval: "YEAR",
    price_metadata: {
      TOKENS: "20000",
    },
    transaction_id: `${user.id}`,
    checkout: "true",
  });

  return (
    <Section>
      <div
        className={cn(
          "mb-6 flex flex-wrap items-center justify-between gap-6 rounded-lg lg:flex-nowrap",
          "px-6 py-4  text-black md:mb-4 mt-4",
          "bg-green-100 dark:bg-green-100 ring-zinc-800 border-s-8 border-green-500",
        )}
      >
        <div>
          <h3 className="mb-1 text-base font-medium">
            <strong className="font-bold">🚨</strong>
            <strong className="font-bold">
              Subscription successfully activated 🎉
            </strong>
            .
          </h3>
          <p className={cn("text-gray-800 text-sm")}>
            Looks like you successfully bought a subscription. Welcome home!
          </p>
        </div>
      </div>
    </Section>
  );
}
